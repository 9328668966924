;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"ca05318bee3773edb9ed3f56fc0f77ad19e752a8"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/plp";import * as Sentry from '@sentry/nextjs';
import type { BrowserOptions } from '@sentry/nextjs';
import type { Client } from '@sentry/types';

import sharedSentryOptions from './shared-sentry-options';

export const initSentry = (
    additionalOptions: BrowserOptions
): Client | undefined =>
    Sentry.init({
        ...sharedSentryOptions,
        ...additionalOptions,
    });
